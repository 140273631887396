
import UserService from '@/shared/services/user.service';
import UserTableService from '@/shared/services/usertable.service';
import { defineComponent, toRaw } from 'vue';
import Loading from 'vue-loading-overlay';
import ResidentialView from './ResidentialView.vue';
import useVuelidate from '@vuelidate/core';
import { required, email, numeric } from '@vuelidate/validators';
import Swal from 'sweetalert2';
import moment from 'moment';
import Vue3Html2pdf from 'vue3-html2pdf'


//Number format
import VueNumberFormat from 'vue-number-format'
import FormulaService from '@/shared/services/formula.service';

export default defineComponent({
    props: {
        foreign: null
    },
    data() {
        return {
            isCommentVisible: false,
            v$: useVuelidate(),
            userSession: null,
            scenarioType: null,
            videoTutorial1: null,
            scenario: {
                user: null,
                users: null,
                userScenarios: null,
                scenarioData: null
            },
            configDatePicker: {
                date: null
            },
            params: {
                scenario: {
                    users: {
                        name: "",
                        phonenumber: "",
                        from: "",
                        to: ""
                    },
                    scenarios: {
                        scenario_code: ""
                    }
                }
            },
            loaderConfig: {
                isLoading: true,
                fullPage: true
            },
            shortTermCash: {
                propertyType: null,
                propertyAddress: null,
                propertyShortTermNumber: null,
                propertyPrice: null,
                //
                propertyClosingCostBuy: null,
                propertyBrokerPayment: null,
                //
                propertyAnnualPropertyTaxes: null,
                propertyMonthlyPropertyTaxes: null,
                propertyAnnualPropertyInsurance: null,
                propertyMonthlyPropertyInsurance: null,
                propertyAnnualFloodTaxes: null,
                propertyMonthlyFloodTaxes: null,
                propertyAnnualFederalTaxes: null,
                propertyMonthlyFederalTaxes: null,
                //
                propertyAssociationPayment: null,
                propertyPaymentMonthlyServices: null,
                propertyOtherMonthlyPayment: null,
                //
                propertyMonthlyPercentageOccupancy: null,
                propertyMonthlyPercentageOccupancyValue: null,
                propertyPriceRentDay: null,
                propertyEstimateMonthlyIncome: null,
                //
                propertyEstimateCapitalGain: null,
                propertyAmortizationDebtCapital: null,
                //
                propertyAnnualBenefit: null,
                propertyAnnualAverage: null,
            },
            customer: {
                name: null,
                phone: null,
                email: null
            },
            scenarioMail: {
                email: null,
                subject: null,
                message: null,
                file: null,
                fileConfig: null
            },
            listPropertyTypes: [],
            listTitleCompanies: [],
            listInspectors: [],
            selectedTitleCompany: null,
            additionalTitleCompanies: [],
            userTitleCompany: {
                companyname: null,
                phonenumber: null,
                email: null
            },
        }
    },
    components: {
        Loading,
        ResidentialView,
        Vue3Html2pdf,
        VueNumberFormat //Component NumberFormat
    },
    validations: {
        customer: {
            name: { required },
            phone: { required, numeric },
            email: { required, email }
        },
        scenarioMail: {
            email: { required },
            subject: { required },
            message: { required },
        }
    },
    created() {
        this.init();
    },
    computed: {
        scenarioValid: function () {
            let k = true;

            if (!this.selectedTitleCompany) k = false;
            if (this.v$.customer.$invalid) k = false;

            return k;
        }
    },
    methods: {
        calculatePropertyEstimateCapitalGain() {
            const price = parseFloat(this.shortTermCash.propertyPrice) || 0;
            const typeMultiplier = parseFloat(this.shortTermCash.propertyType.text) || 0;
            this.shortTermCash.propertyEstimateCapitalGain = ((price * typeMultiplier) / 100) * 5;
        },
        toggleComment(){
            this.isCommentVisible = !this.isCommentVisible;

        },
        init: function () {
            this.getSession();
            this.getTables();
            this.loadSectionUsers();
            this.getScenarioType(this.$route.name.toString().replaceAll("-", "_"));
            // this.loadTableTemplate("titlecompany_table");

            setTimeout(() => {
                document.getElementById("propertyPrice").oninput = () => {
                    if (this.shortTermCash.propertyClosingCostBuy?.calculate) this.shortTermCash.propertyClosingCostBuy.calculate();
                }
            }, 3000)
        },
        submitFormula: async function (property: string, event: any = null) {
            if (event) event.target.disabled = true;

            let error = false;

            const params = {
                scenario: this.scenarioType.scenario_type_id,
                property: property,
                values: {
                    propertyPrice: this.shortTermCash.propertyPrice,
                    propertyClosingCostBuy: this.shortTermCash.propertyClosingCostBuy?.text,
                    propertyBrokerPayment: this.shortTermCash.propertyBrokerPayment,
                    propertyAnnualPropertyTaxes: this.shortTermCash.propertyAnnualPropertyTaxes,
                    propertyMonthlyPropertyTaxes: this.shortTermCash.propertyMonthlyPropertyTaxes,
                    propertyAnnualPropertyInsurance: this.shortTermCash.propertyAnnualPropertyInsurance,
                    propertyMonthlyPropertyInsurance: this.shortTermCash.propertyMonthlyPropertyInsurance,
                    propertyAnnualFloodTaxes: this.shortTermCash.propertyAnnualFloodTaxes,
                    propertyMonthlyFloodTaxes: this.shortTermCash.propertyMonthlyFloodTaxes,
                    propertyAnnualFederalTaxes: this.shortTermCash.propertyAnnualFederalTaxes,
                    propertyMonthlyFederalTaxes: this.shortTermCash.propertyMonthlyFederalTaxes,
                    propertyAssociationPayment: this.shortTermCash.propertyAssociationPayment,
                    propertyPaymentMonthlyServices: this.shortTermCash.propertyPaymentMonthlyServices,
                    propertyOtherMonthlyPayment: this.shortTermCash.propertyOtherMonthlyPayment,
                    propertyMonthlyPercentageOccupancy: this.shortTermCash.propertyMonthlyPercentageOccupancy,
                    propertyRentDay: this.shortTermCash.propertyPriceRentDay,
                    propertyEstimateMonthlyIncome: this.shortTermCash.propertyEstimateMonthlyIncome,
                    propertyEstimateCapitalGain: this.shortTermCash.propertyEstimateCapitalGain ? (this.shortTermCash.propertyEstimateCapitalGain * 100) : null,
                }
            }

            this.shortTermCash.propertyAnnualBenefit = 
                params.values.propertyPrice +
                params.values.propertyClosingCostBuy +
                params.values.propertyBrokerPayment +
                params.values.propertyAnnualPropertyTaxes +
                params.values.propertyMonthlyPropertyTaxes +
                params.values.propertyAnnualPropertyInsurance +
                params.values.propertyMonthlyPropertyInsurance +
                params.values.propertyAnnualFloodTaxes +
                params.values.propertyMonthlyFloodTaxes +
                params.values.propertyAnnualFederalTaxes +
                params.values.propertyMonthlyFederalTaxes +
                params.values.propertyAssociationPayment +
                params.values.propertyPaymentMonthlyServices +
                params.values.propertyOtherMonthlyPayment +
                params.values.propertyMonthlyPercentageOccupancy +
                params.values.propertyRentDay +
                params.values.propertyEstimateMonthlyIncome +
                params.values.propertyEstimateCapitalGain
      
        },
        openModal: function (option: any) {
            let _window: any = window;
            let bootstrap = _window.bootstrap;

            this.videoTutorial1 = option; // Asigna el video directamente a videoTutorial1

            const modal = new bootstrap.Modal('#exampleModal3');
            modal.show();

            const modalopen = document.getElementById('exampleModal3');
            modalopen.addEventListener('hidden.bs.modal', event => {
                this.videoTutorial1 = null; // Limpia la variable del video al cerrar el modal
            });
        },
        changeRealtor: function($event) {
            console.log($event);
            this.getTables();
        },
        loadScenario: function (scenario) {
            if (scenario) {
                let scenariodata = scenario.data ? JSON.parse(scenario.data) : null;
                if (!scenariodata) return;

                delete scenario.data;
                this.scenario.scenarioData = { ...scenario, ...scenariodata };
                console.log(this.scenario.scenarioData);
            }
        },
        commaFormat(value) {
            if (!value) return '';
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        sendScenarioMail: async function ($event) {
            $event.target.disabled = true;
            this.loaderConfig.isLoading = true;
            let formdata = new FormData();
            let files: any[] = [this.scenarioMail.file];
            formdata.append("name", this.scenarioMail.email);
            formdata.append("to", this.scenarioMail.email);
            formdata.append("subject", this.scenarioMail.subject);
            formdata.append("message", this.scenarioMail.message);
            files.forEach((a, w) => {
                formdata.append("files[]", a);
            })

            const response = (await UserService.sendUserMail(formdata)).data;

            if (!response.error) {

                for (let w in this.scenarioMail) {
                    this.scenarioMail[w] = null;
                }

                Swal.fire({
                    title: "Message",
                    text: "Mail sended!",
                    icon: "success",
                    timer: 3500,
                    timerProgressBar: true,
                    showConfirmButton: false,
                    showCloseButton: false,
                    showCancelButton: false
                })
            }
            else {
                console.log(response);
            }
            $event.target.disabled = false;
            this.loaderConfig.isLoading = false;
        },
        generateMail: function () {
            let ref: any = this.$refs.html2Pdf;
            ref.generatePdf();
        },
        beforeFileGenerated: function (event) {
            this.scenarioMail.fileConfig = event;
            // this.scenarioMail.email = this.scenario.scenarioData.user.user_email;
            document.querySelector(".content-wrapper").innerHTML = document.getElementById("printable-target").innerHTML;
            document.querySelector(".content-wrapper").classList.add("tw-p-10");
            this.scenarioMail.fileConfig.html2pdf().from(this.scenarioMail.fileConfig.pdfContent).toPdf().output('blob').then((data: any) => {
                let file = new File([data], this.$route.name.toString().split("-").map(a => a[0].toUpperCase()).toString().replaceAll(",", "") + "_" + this.scenario.scenarioData.user.user_name.toUpperCase() + "_" + moment().format("MMDDYYYY") + ".pdf", { type: 'application/pdf' });
                this.scenarioMail.file = file;
                let w: any = window;
                w.modalclose("#" + document.querySelectorAll("div .modal[id*=exampleModalEscenario]")[0].id);
                w.openModal("exampleModalMessage");

                console.log(this.scenarioMail);
            })
        },
        printScenario: function () {
            document.getElementById("printable-template").innerHTML = document.getElementById("printable-target").innerHTML;
            window.print();
        },
        calculateProperty: function (type: string) {
            if (type == "monthlypercentageoccupancy") {
                if (this.shortTermCash.propertyMonthlyPercentageOccupancy) {
                    this.shortTermCash.propertyMonthlyPercentageOccupancyValue = (this.shortTermCash.propertyMonthlyPercentageOccupancy / 100) * 30;
                }
                else {
                    this.shortTermCash.propertyMonthlyPercentageOccupancyValue = null;
                }
            }

            if (type == "estimatemonthlyincome" || type == "monthlypercentageoccupancy") {
                if (this.shortTermCash.propertyPriceRentDay && this.shortTermCash.propertyMonthlyPercentageOccupancyValue) {
                    this.shortTermCash.propertyEstimateMonthlyIncome = this.shortTermCash.propertyPriceRentDay * this.shortTermCash.propertyMonthlyPercentageOccupancyValue;
                }
                else {
                    this.shortTermCash.propertyEstimateMonthlyIncome = null;
                }
            }

            if (type == "monthlytaxes") {
                this.shortTermCash.propertyMonthlyPropertyTaxes = ((parseFloat(this.shortTermCash.propertyAnnualPropertyTaxes ? this.shortTermCash.propertyAnnualPropertyTaxes : 0) / 12)).toFixed(0)
            }

            if (type == "monthlystatetaxes") {
                this.shortTermCash.propertyMonthlyFloodTaxes = ((parseFloat(this.shortTermCash.propertyAnnualFloodTaxes ? this.shortTermCash.propertyAnnualFloodTaxes : 0) / 12)).toFixed(0)
            }

            if (type == "monthlyfederaltaxes") {
                this.shortTermCash.propertyMonthlyFederalTaxes = ((parseFloat(this.shortTermCash.propertyAnnualFederalTaxes ? this.shortTermCash.propertyAnnualFederalTaxes : 0) / 12)).toFixed(0)
            }

            if (type == "estimated") {
                this.shortTermCash.propertyMonthlyPropertyInsurance = (((this.shortTermCash.propertyAnnualPropertyInsurance ? this.shortTermCash.propertyAnnualPropertyInsurance : 0) / 12)).toFixed(0)
            }
        },
        changeDate: function () {
            if (this.configDatePicker.date) {
                this.params.scenario.users.from = moment(toRaw(this.configDatePicker.date)[0]).format("YYYY-MM-DD"),
                    this.params.scenario.users.to = moment(toRaw(this.configDatePicker.date)[1]).format("YYYY-MM-DD")
            }
            else {
                this.params.scenario.users.from = "";
                this.params.scenario.users.to = "";
            }
        },
        addUserExtraTables: async function () {
            let params: any = {
                user: this.userSession.iduser,
                tabletype: ["mlo_primary", "mlo_second", "mlo_investment", "mlo_special", "titlecompany_table", "usertable_mlo", "usertable_titlecompany"]
            }

            params.tabletype = params.tabletype.toString();

            const response = (await UserTableService.getTables(params)).data

            if (!response.error) {
                let data = response.data;
                let datatable = data.map(a => { return { ...a, tabledata: UserTableService.convertTable(JSON.parse(a.tabledata)), id_tabletype: btoa(a.tabletype) } });

                let users = [...data.filter(a => a.tabletype.includes("usertable"))];

                users.forEach((u, z) => {
                    if (u.tabletype.includes("titlecompany")) {
                        u.tables = datatable.filter(ut => !ut.tabletype.includes("usertable") && ut.tabletype.includes("titlecompany"))
                    }

                    let user = { ...u, ...JSON.parse(u.tabledata) }
                    delete user.tabledata;

                    if (u.tabletype.includes("titlecompany")) this.listTitleCompanies.push(user);
                });

            }
            else {
                this.$toast.error("Error loading tables", { position: 'top-right' })
                console.log(response.message)
            }

            this.loaderConfig.isLoading = false;
        },
        loadScenarios: async function (user) {
            this.loaderConfig.isLoading = true

            const params = { createdby: this.userSession.iduser, user: user.scenario_user_id, scenario: this.scenarioType.scenario_type_id }
            const response = (await UserService.getScenarios(params)).data;

            if (!response.error) {
                this.scenario.userScenarios = response.data;
                if (this.scenario.userScenarios.results.length > 0) {
                    this.scenario.userScenarios.results = this.scenario.userScenarios.results.map(a => { return { ...a, date: moment(a.date).format("DD/MM/YYYY"), type_name: a.type_name.replaceAll("_", " ") } });
                }
                else {
                    this.scenario.userScenarios = [];
                }
            }
            else {
                this.$toast.error(response.message, { position: 'top-right' })
                console.log(response.message)
            }

            this.loaderConfig.isLoading = false;
        },
        loadTableTemplate: async function (type) {
            this.loaderConfig.isLoading = true;

            const params = { type: type }
            const response = (await UserTableService.getTemplate(params)).data;

            if (!response.error) {
                let tables = UserTableService.convertTable(JSON.parse(response.data[0].tabledata));

                if (type == 'titlecompany_table' && this.additionalTitleCompanies.length == 0) {
                    let o: any = { ...this.userTitleCompany, added: true }

                    o.tables = [
                        {
                            tabletype: type,
                            tabledata: tables
                        }
                    ]

                    this.additionalTitleCompanies.push(o);
                }

            }
            else {
                this.$toast.error(response.message, { position: 'top-right' })
                console.log(response.message)
            }

            this.loaderConfig.isLoading = false;
        },
        getScenarioUsers: async function () {
            this.loaderConfig.isLoading = true

            const params = { ...this.params.scenario.users, scenario: this.scenarioType.scenario_type_id }
            const response = (await UserService.getScenarioUsers(params)).data;

            console.log(params);

            if (!response.error) {
                this.scenario.users = response.data;
                if (this.scenario.users.results.length > 0) {
                    this.scenario.users.results = this.scenario.users.results.map(a => { return { ...a, create_date: moment(a.create_date).format("DD/MM/YYYY") } })
                }
            }
            else {
                this.$toast.error(response.message, { position: 'top-right' })
                console.log(response.message)
            }

            this.loaderConfig.isLoading = false;
        },
        addTitleCompanyTable: function () {
            let title = this.listTitleCompanies.findIndex(a => a.added);
            if (title >= 0) {
                this.listTitleCompanies[title] = this.additionalTitleCompanies[0];
            }
            else {
                this.listTitleCompanies.push(this.additionalTitleCompanies[0]);
            }

            Swal.fire({
                title: "Message",
                text: "Saved",
                icon: "success",
                timer: 3000,
                timerProgressBar: false
            });
        },
        getScenarioType: async function (name) {
            this.loaderConfig.isLoading = true

            // if (this.foreign) name = this.foreign;

            const params = { name: this.foreign ? "foreign_" + this.foreign:name }
            const response = await UserService.getScenarioTypes(params);

            if (!response.data.error) {
                if (response.data.data.length > 0) {
                    this.scenarioType = response.data.data[0];
                    this.getScenarioUsers();
                }
            }
            else {
                this.$toast.error(response.data.message, { position: 'top-right' })
                console.log(response.data.message)
            }

            this.loaderConfig.isLoading = false;
        },

        getSession: function () {
            let session = localStorage.getItem("usersession") && localStorage.getItem("usersession").length > 0 ? JSON.parse(localStorage.getItem("usersession")) : null

            if (session) {
                this.userSession = session
            } else {
                UserService.logout("Session expired!")
            }
        },
        loadSectionUsers: async function () {
            this.loaderConfig.isLoading = true;
            let params = {
                zipcode: this.userSession?.zipcode
            }

            let response = (await UserService.getSectionUsers(params)).data;

            if (!response.error) {
                this.listTitleCompanies = toRaw(response.data.titlecompany_users);
                this.listTitleCompanies = this.listTitleCompanies.map((w) => {
                    if (w.tables) {
                        w.tables = w.tables.map((a: any) => {
                            a.tabledata = UserTableService.convertTable(JSON.parse(a.tabledata));
                            return a;
                        })
                    }

                    return toRaw(w);
                })

                this.listInspectors = toRaw(response.data.inspector_users);
                this.listInspectors = this.listInspectors.map((w) => {
                    if (w.tables) {
                        w.tables = w.tables.map((a: any) => {
                            a.tabledata = UserTableService.convertTable(JSON.parse(a.tabledata));
                            return a;
                        })
                    }

                    return toRaw(w);
                })

                this.addUserExtraTables();
            }
            else {
                console.log(response.message)
            }

            this.loaderConfig.isLoading = false;
        },
        getTables: async function () {
            this.loaderConfig.isLoading = true
            let tabletype = `${this.userSession.role.replaceAll(" ", "").toLowerCase()}_table`

            let usertables: any[] = [];

            let q = {
                user: this.userSession.id_user ? this.userSession.id_user : this.userSession.iduser,
                tabletype: tabletype
            }

            const response = (await UserTableService.getTables(q)).data
            if (!response.error) {
                let responsedata = response.data.length > 0 ? response.data[0] : null;

                if (responsedata) {
                    let tabledata = JSON.parse(responsedata.tabledata);

                    usertables = UserTableService.convertTable(tabledata);
                    this.listPropertyTypes = usertables.find(a => a.id == '1').data.map((a: any, e: number) => { return { id: e, name: a.table_name, text: parseFloat(a.table_text) / 100 } });

                    let taxes = usertables.find(a => a.id == '4').data
                    let comissions = usertables.find(a => a.id == '5').data

                    taxes.forEach((tax: any) => {
                        // if (tax.table_name == "FEDERAL TAX") {
                        //     this.shortTermCash.propertyStateTax = tax.table_text;
                        // }

                        // if (tax.table_name == "PROPERTY INSURANCE") {
                        //     this.shortTermCash.propertyInsurance = tax.table_text;
                        // }

                        // if (tax.table_name == "PROPERTY FLOOD INSURANCE") {
                        //     this.shortTermCash.propertyFloodInsurance = tax.table_text;
                        // }

                    })

                    comissions.forEach((commission: any) => {
                        if (commission.table_name == "REAL ESTATE BROKER FEE") {
                            this.shortTermCash.propertyBrokerPayment = commission.table_text;
                        }
                    })

                    console.log(usertables);

                }
            }

            this.loaderConfig.isLoading = false;
        },
        getItTitleCompany: function (user: any) {
            this.selectedTitleCompany = user;
            this.shortTermCash.propertyClosingCostBuy = this.selectedTitleCompany.tables.find((a: any) => a.tabletype == "titlecompany_table").tabledata.find((e: any) => e.id == "1").data.find((j: any) => j.table_name == "CLOSING CLOSE").table_closing_close;

            const table = this.selectedTitleCompany.tables.find((a: any) => a.tabletype == "titlecompany_table").tabledata;
            const closingconsts = table.find((e: any) => e.id == "1").data;
            const otherprices = table.find((e: any) => e.id == "2").data;
            closingconsts.forEach((a: any) => {
                if (a.table_name3 == "BUYER") {
                    this.shortTermCash.propertyClosingCostBuy = { text: null, value: a.table_closing_close ? a.table_closing_close / 100 : 0 };
                    this.shortTermCash.propertyClosingCostBuy.calculate = () => {
                        this.shortTermCash.propertyClosingCostBuy.text = (this.shortTermCash.propertyPrice * this.shortTermCash.propertyClosingCostBuy.value).toFixed(0);
                    }

                    if (this.shortTermCash.propertyPrice) this.shortTermCash.propertyClosingCostBuy.calculate();
                }
            })

            Swal.fire({
                title: "Title Company",
                text: this.selectedTitleCompany.companyname + " selected",
                icon: "success",
                timer: 3500,
                timerProgressBar: true,
                showConfirmButton: false,
                showCloseButton: false,
                showCancelButton: false
            })
        },
        submitScenario: async function ($event) {
            this.loaderConfig.isLoading = true;

            $event.disabled = true;

            let px = this.$route.name;

            if (this.foreign) {
                px = "foreign-" + this.foreign.replaceAll("_", "-");
            }

            let params: any = {
                code: px.toString().split("-").map(a => a[0].toUpperCase()).toString().replaceAll(",", "") + "-" + btoa(Date.now().toString()).toUpperCase().replaceAll("=", ""),
                date: moment(new Date()).format("YYYY-MM-DD"),
                type: this.scenarioType.scenario_type_id,
                user_email: this.customer.email,
                user_phonenumber: this.customer.phone,
                user_name: this.customer.name,
                data: {
                    titlecompany: { ...this.selectedTitleCompany },
                    financial_information: { ...this.shortTermCash },
                },
                id_user: this.userSession.iduser
            }

            if (this.foreign) {
                params.data.scenario_type = this.foreign;
            }

            params.data = JSON.stringify(params.data);

            let response = (await UserService.saveScenario(params)).data;

            if (!response.error) {
                Swal.fire({
                    title: "Realtor",
                    text: response.message,
                    icon: "success",
                    timer: 3500,
                    timerProgressBar: true,
                    showConfirmButton: false,
                    showCloseButton: false,
                    showCancelButton: false
                }).then(() => {
                    window.location.reload();
                })
            }
            else {
                console.log(response.message)
            }

            this.loaderConfig.isLoading = false;
            $event.disabled = false;
        }
    }
})
