import Swal from 'sweetalert2'

interface ToastOptions {
  title?: string
  icon?: 'success' | 'error' | 'warning' | 'info' | 'question'
  position?: 'top' | 'top-start' | 'top-end' | 'center' | 'center-start' | 'center-end' | 'bottom' | 'bottom-start' | 'bottom-end'
  timer?: number
  timerProgressBar?: boolean
  showConfirmButton?: boolean
  customClass?: {
    popup?: string
    [key: string]: string | undefined
  },
  showCloseButton?: boolean
}

export function useToast() {
  // Initialize the toast with default settings
  const Toast = Swal.mixin({
    toast: true,
    position: 'center',
    iconColor: 'white',
    customClass: {
      popup: 'colored-toast',
    },
    showConfirmButton: false,
    timer: 1500,
    timerProgressBar: true,
  })

  // Main toast function
  const showToast = async (options: ToastOptions) => {
    return await Toast.fire({
      ...options
    })
  }

  // Convenience methods for different toast types
  const success = async (title: string, options?: Partial<ToastOptions>) => {
    return await showToast({
      icon: 'success',
      title,
      ...options
    })
  }

  const error = async (title: string, options?: Partial<ToastOptions>) => {
    return await showToast({
      icon: 'error',
      title,
      ...options
    })
  }

  const warning = async (title: string, options?: Partial<ToastOptions>) => {
    return await showToast({
      icon: 'warning',
      title,
      ...options
    })
  }

  const info = async (title: string, options?: Partial<ToastOptions>) => {
    return await showToast({
      icon: 'info',
      title,
      ...options
    })
  }

  const question = async (title: string, options?: Partial<ToastOptions>) => {
    return await showToast({
      icon: 'question',
      title,
      ...options
    })
  }

  // Method to show multiple toasts in sequence
  const showSequence = async (toasts: ToastOptions[]) => {
    for (const toast of toasts) {
      await showToast(toast)
    }
  }

  return {
    showToast,
    success,
    error,
    warning,
    info,
    question,
    showSequence
  }
}
