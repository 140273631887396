
import UserService from '@/shared/services/user.service';
import UserTableService from '@/shared/services/usertable.service';
import { defineComponent, toHandlers, toRaw } from 'vue';
import Loading from 'vue-loading-overlay';
import ResidentialView from './ResidentialView.vue';
import useVuelidate from '@vuelidate/core';
import { required, email, numeric } from '@vuelidate/validators';
import Swal from 'sweetalert2';
import moment from 'moment';
import Vue3Html2pdf from 'vue3-html2pdf'

//Number format
import VueNumberFormat from 'vue-number-format'
import FormulaService from '@/shared/services/formula.service';

export default defineComponent({
    props: {
        foreign: null,
        header: {
            default: null
        }
    },
    data() {
        return {
            v$: useVuelidate(),
            userSession: null,
            userSection: null,
            scenarioType: null,
            scenario: {
                users: null,
                userScenarios: null,
                scenarioData: null
            },
            filters: {
                property: null,
                score: null,
                program: null,
                year: null,
                program_name: "",
                active: false
            },
            loaderConfig: {
                isLoading: true,
                fullPage: true
            },
            listUserSections: [],
            listTableProperties: [],
            
            configDatePicker: {
                date: null
            },
            videoTutorial1: null,
            params: {
                scenario: {
                    users: {
                        name: "",
                        phonenumber: "",
                        from: "",
                        to: ""
                    },
                    scenarios: {
                        scenario_code: ""
                    }
                }
            },
            listYears: [
                {
                    id: 0,
                    name: "15 YEARS",
                    value: 15
                },
                {
                    id: 1,
                    name: "30 YEARS",
                    value: 30
                }
            ],
            selection: {
                selectedUser: null,
               
                selectedYears: null
            },
            financialInformation: {
                propertyPrice: null,
                downPayment: null,
                mortgage: null,
                pmi: null,
                propertyTaxes: null,
                propertyInsurance: null,
                stateTaxes: null,
                propertyFloodInsurance: null,
                association: 0,
                otherMontlhyPayment: null,
                loanAmount: null,
                propertyClosingCostBuy: null,
                totalEstimalMonthlyPayment: null
            },
            customer: {
                name: null,
                phone: null,
                email: null
            },
            scenarioMail: {
                email: null,
                subject: null,
                message: null,
                file: null,
                fileConfig: null
            },
            listPropertyTypes: [],
            listTitleCompanies: [],
            listInspectors: [],
            selectedTitleCompany: null,
            tables: [],
            userMLO: {
                username: null,
                lastname: null,
                phonenumber: null,
                email: null
            },
            tablesMLO: [],
            titleTable: "Primary",
            tableTypesMLO: [
                {
                    key: "mlo_primary",
                    name: "Primary",
                    visible: true,
                    tables: []
                },
                {
                    key: "mlo_second",
                    name: "Second",
                    visible: false,
                    tables: []
                },
                {
                    key: "mlo_investment",
                    name: "Investment",
                    visible: false,
                    tables: []
                },
                {
                    key: "mlo_special",
                    name: "Special",
                    visible: false,
                    tables: []
                },
                {
                    key: "mlo_foreign",
                    name: "Foreign",
                    visible: false,
                    tables: []
                }
            ],
            pmi: null
        }
    },
    computed: {
        // pmi() {
        //     let j = 0;
        //     if (this.selection.selectedUser) {
        //         this.financialInformation.pmi = parseFloat(this.selection.selectedUser.average_mip_pmi) * this.financialInformation.loanAmount / 12;

        //         let l = this.financialInformation.propertyPrice * 0.20;
        //         this.financialInformation.pmi = this.financialInformation.downPayment < l ? this.financialInformation.pmi.toFixed(2) : 0;
        //     }
        //     else {
        //         this.financialInformation.pmi = 0;
        //     }

        //     j = this.financialInformation.pmi;

        //     return j;
        // },
        scenarioValid: function () {
            let k = true;

            if (!this.selection.selectedUser || !this.selection.selectedYears) {
                k = false;
            }

            if (this.v$.customer.$invalid) k = false;

            return k;
        },
        loanAmount() {
            let result = 0
            if (this.financialInformation.propertyPrice !== 0) {
                result = this.financialInformation.propertyPrice - this.financialInformation.downPayment
            }
            return parseFloat(result.toFixed(2))
        },
        propertyTaxes() {
            let result = 0
            if (this.financialInformation.propertyPrice !== 0 && this.financialInformation.propertyTaxes !== null) {
                result = (this.financialInformation.propertyPrice * parseFloat(this.financialInformation.propertyTaxes.text) / 12) / 100
            }
            return parseFloat(result.toFixed(2))
        },
        federalTaxes() {
            let result = 0
            if (this.financialInformation.propertyPrice !== 0 && this.financialInformation.stateTaxes !== null) {
                result = (this.financialInformation.propertyPrice * parseFloat(this.financialInformation.stateTaxes.text) / 12) / 100
            }
            return parseFloat(result.toFixed(2))
        },
        propertyInsurance() {
            let result = 0
            if (this.financialInformation.propertyPrice !== 0 && this.financialInformation.propertyInsurance !== null) {
                result = (this.financialInformation.propertyPrice * parseFloat(this.financialInformation.propertyInsurance.text) / 12) / 100
            }
            return parseFloat(result.toFixed(2))
        },
        monthlyPayment() {
            let interest_rate = 0;
            let result = 0
            let years = 1;
            if (this.selection.selectedYears !== undefined && this.selection.selectedYears !== null) {
                years = this.selection.selectedYears.value * 12
            }
            if (this.selection.selectedUser) {
                interest_rate = (typeof this.selection.selectedUser.interes_rate) === 'number' ? this.selection.selectedUser.interes_rate : parseFloat(this.selection.selectedUser.interes_rate.replace('%', ''))
                const interestRateRatio = (interest_rate / 100) / 12

                const calcTop = interestRateRatio * this.loanAmount
                const calcBottom = 1 - (1 / Math.pow(1 + interestRateRatio, years))

                const interest = this.loanAmount * interestRateRatio
                const capital = calcTop / calcBottom - interest
                result = capital + interest
            }
            return parseFloat(result.toFixed(2))
        },
        totalMonthly() {
            let result = 0
            if (this.financialInformation.association !== null) {

                if (this.foreign) {
                    result = this.monthlyPayment + this.propertyTaxes + this.federalTaxes + this.propertyInsurance + this.financialInformation.association + this.financialInformation.otherMontlhyPayment
                } else {
                    result = this.monthlyPayment + this.propertyTaxes + this.federalTaxes + this.propertyInsurance + parseFloat(this.pmi) + this.financialInformation.association + this.financialInformation.otherMontlhyPayment
                }
            }
            return parseFloat(result.toFixed(2))
        }
    },
    validations: {
        customer: {
            name: { required },
            phone: { required, numeric },
            email: { required, email }
        },
        scenarioMail: {
            email: { required },
            subject: { required },
            message: { required },
        }
    },
    components: {
        Loading,
        ResidentialView,
        Vue3Html2pdf,
        VueNumberFormat //Component NumberFormat
    },
    created() {
        this.init();
    },
    methods: {
        init: function () {
            this.getSession();
            this.loadSectionUsers();
            this.loadTable();
            this.getScenarioType(this.$route.name.toString().replaceAll("-", "_"));
            this.getTableTemplate();

            let _context = this;
            setTimeout(() => {

                // Property Price Change
                let el: any = document.getElementById("propertyPriceTotalFinancial");
                el.addEventListener("input", () => {
                    console.log(_context.financialInformation.propertyPrice)
                    _context.calculateField('loanamount');
                })


                let downPayment: any = document.getElementById("downPaymentTotalFinancial");
                downPayment.addEventListener("input", () => {
                    _context.calculateField('loanamount');
                })

                // let loanAmount: any = document.getElementById("loanAmount");
                // downPayment.addEventListener("input", () => {
                //     _context.calculateField('loanamount');
                // })
            }, 3000)
        },
        submitFormula: async function (property: string, event: any = null) {
            const params = {
                scenario: this.scenarioType.scenario_type_id,
                property: property,
                values: {
                    propertyPrice: this.financialInformation.propertyPrice,
                    propertyDownPayment: this.financialInformation.downPayment,
                    propertyLoanAmount: this.financialInformation.loanAmount,
                    propertyInterestRate: this.selection.selectedUser ? this.selection.selectedUser.interes_rate.toString().replace("%", "") : null,
                    propertySelectedYears: this.selection.selectedYears ? this.selection.selectedYears.value : null,
                    propertyPMI: this.pmi,
                    propertyTaxes: this.financialInformation.propertyTaxes?.value,
                    propertyInsurance: this.financialInformation.propertyInsurance?.value,
                    propertyStateTaxes: this.financialInformation.stateTaxes?.value,
                    propertyFloodInsurance: this.financialInformation.propertyFloodInsurance?.value,
                    propertyAssociation: this.financialInformation.association,
                    propertyOtherMonthlyPayment: this.financialInformation.otherMontlhyPayment
                }
            }


            this.financialInformation.totalEstimalMonthlyPayment =
                params.values.propertyPrice +
                params.values.propertyDownPayment +
                params.values.propertyLoanAmount +
                params.values.propertyInterestRate +
                params.values.propertySelectedYears +
                params.values.propertyPMI +
                params.values.propertyTaxes +
                params.values.propertyInsurance +
                params.values.propertyStateTaxes +
                params.values.propertyFloodInsurance +
                params.values.propertyAssociation +
                params.values.propertyOtherMonthlyPayment;

        },
        changeRealtor: function ($event) {
            console.log($event);
            this.loadTable();
        },
        commaFormat(value) {
            if (!value) return '';
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        changeDate: function () {
            if (this.configDatePicker.date) {
                this.params.scenario.users.from = moment(toRaw(this.configDatePicker.date)[0]).format("YYYY-MM-DD"),
                    this.params.scenario.users.to = moment(toRaw(this.configDatePicker.date)[1]).format("YYYY-MM-DD")
            }
            else {
                this.params.scenario.users.from = "";
                this.params.scenario.users.to = "";
            }
        },
        sendScenarioMail: async function ($event) {
            $event.target.disabled = true;
            this.loaderConfig.isLoading = true;
            let formdata = new FormData();
            let files: any[] = [this.scenarioMail.file];
            formdata.append("name", this.scenarioMail.email);
            formdata.append("to", this.scenarioMail.email);
            formdata.append("subject", this.scenarioMail.subject);
            formdata.append("message", this.scenarioMail.message);
            files.forEach((a, w) => {
                formdata.append("files[]", a);
            })

            const response = (await UserService.sendUserMail(formdata)).data;

            if (!response.error) {

                for (let w in this.scenarioMail) {
                    this.scenarioMail[w] = null;
                }

                Swal.fire({
                    title: "Message",
                    text: "Mail sended!",
                    icon: "success",
                    timer: 3500,
                    timerProgressBar: true,
                    showConfirmButton: false,
                    showCloseButton: false,
                    showCancelButton: false
                })
            }
            else {
                console.log(response);
            }
            $event.target.disabled = false;
            this.loaderConfig.isLoading = false;
        },
        openModal: function (option: any) {
            let _window: any = window;
            let bootstrap = _window.bootstrap;

            this.videoTutorial1 = option; // Asigna el video directamente a videoTutorial1

            const modal = new bootstrap.Modal('#exampleModal3');
            modal.show();

            const modalopen = document.getElementById('exampleModal3');
            modalopen.addEventListener('hidden.bs.modal', event => {
                this.videoTutorial1 = null; // Limpia la variable del video al cerrar el modal
            });
        },
        generateMail: function () {
            let ref: any = this.$refs.html2Pdf;
            ref.generatePdf();
        },
        beforeFileGenerated: function (event) {
            this.scenarioMail.fileConfig = event;
            // this.scenarioMail.email = this.scenario.scenarioData.user.user_email;
            document.querySelector(".content-wrapper").innerHTML = document.getElementById("printable-target").innerHTML;
            document.querySelector(".content-wrapper").classList.add("tw-p-10");
            this.scenarioMail.fileConfig.html2pdf().from(this.scenarioMail.fileConfig.pdfContent).toPdf().output('blob').then((data: any) => {
                let file = new File([data], this.$route.name.toString().split("-").map(a => a[0].toUpperCase()).toString().replaceAll(",", "") + "_" + this.scenario.scenarioData.user.user_name.toUpperCase() + "_" + moment().format("MMDDYYYY") + ".pdf", { type: 'application/pdf' });
                this.scenarioMail.file = file;
                let w: any = window;
                w.modalclose("#" + document.querySelectorAll("div .modal[id*=exampleModalEscenario]")[0].id);
                w.openModal("exampleModalMessage");

                // console.log(this.scenarioMail);
            })
        },
        printScenario: function () {
            document.getElementById("printable-template").innerHTML = document.getElementById("printable-target").innerHTML;
            window.print();
        },
        calculateField: function (field) {
            if (field == "loanamount") {
                if (this.financialInformation.downPayment) {
                    this.financialInformation.loanAmount = (this.financialInformation.propertyPrice ? this.financialInformation.propertyPrice : 0) - (this.financialInformation.downPayment ? this.financialInformation.downPayment : 0);

                    if (this.selection.selectedUser && this.financialInformation.propertyPrice && this.financialInformation.downPayment && !this.foreign) {
                        let avgPmi = this.selection.selectedUser.average_mip_pmi;
                        console.log(avgPmi, this.selection.selectedUser.minimum_down_payment)
                        avgPmi = parseFloat(avgPmi.replace('%', ''))
                        avgPmi = avgPmi / 100;

                        let percentage = this.financialInformation.downPayment / this.financialInformation.propertyPrice;
                        console.log(percentage, (this.selection.selectedUser.minimum_down_payment || this.selection.selectedUser.minimum_down_payment != "-" ? (this.selection.selectedUser.minimum_down_payment / 100) : 0))
                        if (percentage <= (this.selection.selectedUser.minimum_down_payment || this.selection.selectedUser.minimum_down_payment != "-" ? (this.selection.selectedUser.minimum_down_payment / 100) : 0)) {
                            let pmi = (avgPmi * this.financialInformation.loanAmount) / 12;
                            this.pmi = parseFloat(pmi.toFixed(1)).toFixed(0);

                        } else {
                            this.pmi = 0;
                        }
                    }
                }
            }

            if (field == "loanamount" || field == "taxes") {
                if (this.financialInformation.propertyPrice) {
                    this.financialInformation.propertyTaxes.value = (((parseFloat(this.financialInformation.propertyTaxes.text) * this.financialInformation.propertyPrice) / 12) / 100).toFixed(0);
                    this.financialInformation.stateTaxes.value = (((parseFloat(this.financialInformation.stateTaxes.text) * this.financialInformation.propertyPrice) / 12) / 100).toFixed(0);
                    this.financialInformation.propertyInsurance.value = (((parseFloat(this.financialInformation.propertyInsurance.text) * this.financialInformation.propertyPrice) / 12) / 100).toFixed(0);
                    this.financialInformation.propertyFloodInsurance.value = (((parseFloat(this.financialInformation.propertyFloodInsurance.text) * this.financialInformation.propertyPrice) / 12) / 100).toFixed(0);
                }
                // else {
                //     this.financialInformation.propertyTaxes.value = this.financialInformation.propertyTaxes.text;
                //     this.financialInformation.stateTaxes.value = this.financialInformation.stateTaxes.text;
                //     this.financialInformation.propertyInsurance.value = this.financialInformation.propertyInsurance.text;
                //     this.financialInformation.propertyFloodInsurance.value = this.financialInformation.propertyFloodInsurance.text;
                // }
            }
        },
        addUserExtraTables: async function () {

            let params: any = {
                user: this.userSession.iduser,
                tabletype: ["mlo_primary", "mlo_second", "mlo_investment", "mlo_special", "mlo_foreign", "titlecompany_table", "usertable_mlo", "usertable_titlecompany"]
            }

            params.tabletype = params.tabletype.toString();

            const response = (await UserTableService.getTables(params)).data

            if (!response.error) {
                let data = response.data;
                let datatable = data.map(a => { return { ...a, tabledata: UserTableService.convertTable(JSON.parse(a.tabledata)), id_tabletype: btoa(a.tabletype) } });

                let users = [...data.filter(a => a.tabletype.includes("usertable"))];

                users.forEach((u, z) => {
                    if (u.tabletype.includes("mlo")) {
                        u.fha = null;
                        u.isManuallyAdded = true;
                        u.interes_rate = null;
                        u.minimum_down_paymentlower = null;
                        u.minimum_down_paymenthigher = null;
                        u.average_mip_pmi = null;
                        u.backend_ratio = null;
                        u.maximum_loan = null;
                        u.minimum_down_payment = null;

                        u.tables = datatable.filter(ut => !ut.tabletype.includes("usertable") && !ut.tabletype.includes("titlecompany"))

                        u.tables.forEach(usertable => {

                            let tabletype = usertable.tabletype;

                            let type = {
                                id: btoa(tabletype),
                                name: tabletype.split("_")[1][0].toUpperCase() + tabletype.split("_")[1].substring(1, tabletype.split("_")[1].length)
                            }

                            if (!this.listTableProperties.find(a => a.id == type.id)) {
                                this.listTableProperties.push(type);
                            }
                        })

                    }

                    if (u.tabletype.includes("titlecompany")) {
                        u.tables = datatable.filter(ut => !ut.tabletype.includes("usertable") && ut.tabletype.includes("titlecompany"))
                    }

                    let user = { ...u, ...JSON.parse(u.tabledata) }
                    delete user.tabledata;

                    if (u.tabletype.includes("mlo")) this.listUserSections.push(user);
                    if (u.tabletype.includes("titlecompany")) this.listTitleCompanies.push(user);
                });

                this.listTableProperties = this.listTableProperties.filter(a => !["investment", "foreign"].includes(a.name.toLowerCase())).map(a => { return { ...a, name: ["primary", "second"].includes(a.name.toLowerCase()) ? a.name + " Property" : a.name + " Program" } })
                // console.log(this.listTableProperties);
            }
            else {
                this.$toast.error("Error loading tables", { position: 'top-right' })
                console.log(response.message)
            }

            this.loaderConfig.isLoading = false;
        },
        loadScenario: function (scenario) {
            if (scenario) {
                let scenariodata = scenario.data ? JSON.parse(scenario.data) : null;
                if (!scenariodata) return;

                delete scenario.data;
                this.scenario.scenarioData = { ...scenario, ...scenariodata };
                // console.log(this.scenario.scenarioData);
            }
        },
        loadScenarios: async function (user) {
            this.loaderConfig.isLoading = true

            const params = { createdby: this.userSession.iduser, user: user.scenario_user_id, scenario: this.scenarioType.scenario_type_id }
            const response = (await UserService.getScenarios(params)).data;

            if (!response.error) {
                this.scenario.userScenarios = response.data;
                if (this.scenario.userScenarios.results.length > 0) {
                    this.scenario.userScenarios.results = this.scenario.userScenarios.results.map(a => { return { ...a, date: moment(a.date).format("DD/MM/YYYY"), type_name: a.type_name.replaceAll("_", " ") } });
                }
                else {
                    this.scenario.userScenarios = [];
                }
            }
            else {
                this.$toast.error(response.message, { position: 'top-right' })
                console.log(response.message)
            }

            this.loaderConfig.isLoading = false;
        },
        addMLOManually: function () {

        },
        changeTableType: function (type) {
            this.tableTypesMLO.forEach(a => {
                a.visible = false

                if (a.key == type) {
                    this.titleTable = a.name;
                    a.visible = true;
                }
            });

        },
        getTableTemplate: async function () {
            this.loaderConfig.isLoading = true;

            let types = this.tableTypesMLO.map(a => a.key).toString();

            const params = { type: types }
            const response = (await UserTableService.getTemplate(params)).data;

            if (!response.error) {
                let tabledata = response.data;
                let tableprimary = tabledata.find(a => a.tabletype == "mlo_primary");
                let tables = UserTableService.convertTable(JSON.parse(tableprimary.tabledata));
                let datatable = tabledata.filter(a => a.tabletype != "mlo_primary").map(a => { return { ...a, tabledata: UserTableService.convertTable(JSON.parse(a.tabledata)), id_tabletype: btoa(a.tabletype) } });
                this.tables = tables;

                this.tableTypesMLO = this.tableTypesMLO.map((a: any) => {
                    let tables = datatable.find(b => b.tabletype == a.key);
                    if (tables) {
                        a.tables = tables.tabledata;
                    }
                    return { ...a };
                })

                // console.log(this.tableTypesMLO);
            }
            else {
                this.$toast.error(response.message, { position: 'top-right' })
                console.log(response.message)
            }

            this.loaderConfig.isLoading = false;
        },
        getScenarioUsers: async function () {
            this.loaderConfig.isLoading = true

            const params = { ...this.params.scenario.users, scenario: this.scenarioType.scenario_type_id }
            const response = (await UserService.getScenarioUsers(params)).data;

            // console.log(params);

            if (!response.error) {
                this.scenario.users = response.data;
                if (this.scenario.users.results.length > 0) {
                    this.scenario.users.results = this.scenario.users.results.map(a => { return { ...a, create_date: moment(a.create_date).format("DD/MM/YYYY") } })
                }
            }
            else {
                this.$toast.error(response.message, { position: 'top-right' })
                console.log(response.message)
            }

            this.loaderConfig.isLoading = false;
        },
        getScenarioType: async function (name) {
            this.loaderConfig.isLoading = true

            // if (this.foreign) name = this.foreign;

            const params = { name: this.foreign ? "foreign_" + this.foreign : name }
            const response = await UserService.getScenarioTypes(params);

            if (!response.data.error) {
                if (response.data.data.length > 0) {
                    this.scenarioType = response.data.data[0];
                    this.getScenarioUsers();
                }
            }
            else {
                this.$toast.error(response.data.message, { position: 'top-right' })
                console.log(response.data.message)
            }

            this.loaderConfig.isLoading = false;
        },
        getSession: function () {
            let session = localStorage.getItem("usersession") && localStorage.getItem("usersession").length > 0 ? JSON.parse(localStorage.getItem("usersession")) : null

            if (session) {
                this.userSession = session
            } else {
                UserService.logout("Session expired!")
            }
        },
        loadData: function (user: any) {
            console.log(user);
            this.selection.selectedUser = user;
            this.financialInformation.pmi = user.average_mip_pmi;

            this.selection.selectedYears = this.listYears.find(c => c.id == this.filters.year);
            this.loadTable();

            Swal.fire({
                title: "Message",
                text: this.selection.selectedUser.username + " " + this.selection.selectedUser.lastname + " selected",
                icon: "success",
                timer: 3500,
                timerProgressBar: true,
                showConfirmButton: false,
                showCloseButton: false,
                showCancelButton: false
            })

            // console.log(user, this.selection);
        },
        loadSectionUsers: async function () {
            this.loaderConfig.isLoading = true;
            let params = {
                zipcode: this.userSession?.zipcode
            }

            let response = (await UserService.getSectionUsers(params)).data;

            if (!response.error) {
                this.userSection = toRaw(response.data);
                if (this.userSection.mlo > 0) {
                    this.listUserSections = this.userSection.mlo_users
                    this.listTitleCompanies = toRaw(this.userSection.titlecompany_users);
                    this.listTitleCompanies = this.listTitleCompanies.map((w) => {
                        if (w.tables) {
                            w.tables = w.tables.map((a: any) => {
                                a.tabledata = UserTableService.convertTable(JSON.parse(a.tabledata));
                                return a;
                            })
                        }

                        return toRaw(w);
                    })

                    this.listInspectors = toRaw(this.userSection.inspector_users);
                    this.listInspectors = this.listInspectors.map((w) => {
                        if (w.tables) {
                            w.tables = w.tables.map((a: any) => {
                                a.tabledata = UserTableService.convertTable(JSON.parse(a.tabledata));
                                return a;
                            })
                        }

                        return toRaw(w);
                    })

                    if (this.listUserSections.length > 0) {
                        this.listUserSections = this.listUserSections.map((u) => {

                            u.fha = null;
                            u.interes_rate = null;
                            u.minimum_down_paymentlower = null;
                            u.minimum_down_paymenthigher = null;
                            u.average_mip_pmi = null;
                            u.backend_ratio = null;
                            u.maximum_loan = null;
                            u.isManuallyAdded = false;
                            u.minimum_down_payment = null;

                            if (u.tables) {
                                u.tables = u.tables.map((t: any) => {
                                    let tables = JSON.parse(t.tabledata);

                                    let formatTables: any[] = [];

                                    if (tables.constructor === Array) {
                                        tables.map((c: any) => {

                                            if (c.convert) {
                                                let listdata: any = []

                                                c.data.forEach((v: any) => {
                                                    let j: any = {}

                                                    v.map((e: any) => {
                                                        let o: any = {}
                                                        e.values.forEach((w: any) => {
                                                            o[`${e.name}_${w.name}`] = w.value
                                                        })

                                                        return o
                                                    }).forEach((p: any) => {
                                                        for (let f in p) {
                                                            j[f] = p[f]
                                                        }
                                                    })

                                                    v = [j]
                                                    listdata.push(j)
                                                })

                                                c.data = listdata
                                            }

                                            formatTables.push(c);
                                        })
                                    }




                                    let tabletype = {
                                        id: btoa(t.tabletype),
                                        name: t.tabletype.split("_")[1][0].toUpperCase() + t.tabletype.split("_")[1].substring(1, t.tabletype.split("_")[1].length)
                                    }

                                    if (!this.listTableProperties.find(a => a.id == tabletype.id)) {
                                        this.listTableProperties.push(tabletype);
                                    }

                                    return { ...t, id_tabletype: tabletype.id, tabledata: tables };
                                })
                            }

                            return toRaw(u)
                        })
                    }
                }

                this.addUserExtraTables();
                // console.log(this.listUserSections)
            }
            else {
                console.log(response.message)
            }

            this.loaderConfig.isLoading = false;
        },
        filterTable: function () {

            let f: any = {
                property: this.listTableProperties.find(a => a.id == this.filters.property),
                score: this.filters.score,

                year: this.listYears.find(a => a.id == this.filters.year)
            }

            let q = 0

            for (let a in f) {
                if (f[a] != undefined) {
                    q++;
                }
            }


            if (q == 4) {
                this.filters.active = true;
                this.filters.program_name = f.program.name;
                this.listUserSections.forEach((user: any) => {

                    user.fha = null;
                    user.interes_rate = "-";
                    user.minimum_down_paymentlower = "-";
                    user.minimum_down_paymenthigher = "-";
                    user.average_mip_pmi = "-";
                    user.backend_ratio = "-";
                    user.maximum_loan = "-";
                    user.minimum_down_payment = "-";

                    if (user.tables) {
                        let t: any = user.tables.find((b: any) => b.id_tabletype == f.property.id)

                        let g = t.tabledata.find(k => k.id == 12);
                        if (g) {
                            g.data.forEach(e => {
                                if (e.table_name == f.program.name) {
                                    user.minimum_down_payment = e.table_value;
                                }
                            })
                        }

                        if (t) {
                            let y: any = t.tabledata.find((o: any) => o.id == (f.year.id + 1).toString())
                            if (y) {
                                const w = (f.program.name.toLowerCase() == "conventional" ? "coventional" : f.program.name.toLowerCase())

                                y.data.forEach((e: any) => {
                                    if (e[w + "_credit"]) {
                                        let min = parseFloat(e[w + "_credit"].split("-")[0].trim());
                                        let max = parseFloat(e[w + "_credit"].split("-")[1].trim());
                                        let s = parseFloat(f.score)

                                        if ((s >= min && s <= max)) {
                                            user.interes_rate = e[w + "_percent"] + "%"

                                            t.tabledata.forEach((j: any) => {
                                                let k = j.data.find((a: any) => a?.table_name?.toLowerCase() == w || a?.table_name?.toLowerCase().includes(f.program.name.toLowerCase()))
                                                if (k) {
                                                    if (k?.table_minimmum_down_payment) user.minimum_down_paymentlower = k?.table_minimmum_down_payment + "%";
                                                    if (k?.table_score_credit_higher_than_b) user.minimum_down_paymenthigher = k?.table_score_credit_higher_than_b + "%";
                                                    // if (k?.table_mip || k?.table_pmi) user.average_mip_pmi = (k?.table_pmi ? k?.table_pmi : k?.table_mip) + "%";
                                                    if (k?.table_backend_ratio) user.backend_ratio = k?.table_backend_ratio + "%";
                                                    if (k?.table_input_mla) user.maximum_loan = "$" + k?.table_input_mla;

                                                    if (k?.table_mip || k?.table_pmi) {
                                                        // if (this.filters.program_name == "FHA" || this.filters.program_name == "VA") user.average_mip_pmi = k?.table_mip + "%";
                                                        // else user.average_mip_pmi = k?.table_pmi + "%";
                                                        user.average_mip_pmi = k?.table_pmi + "%";
                                                    }
                                                }
                                            })
                                        }
                                    }
                                })

                            }
                        }
                    }
                })
            }

            if (this.foreign && f.year) {
                this.filters.active = true;
                this.listUserSections.forEach((user: any) => {

                    user.interes_rate = "-";
                    user.minimum_down_payment = "-";

                    if (user.tables) {
                        let t = user.tables.find(a => a.tabletype == "mlo_foreign");
                        if (t) {
                            let w = t.tabledata.find(b => b.id == '1');
                            if (w) {
                                w.data.forEach(g => {
                                    if (g.table_name == "YEARS" && g.table_text == f.year.value) {
                                        user.interes_rate = w.data.find(d => d.table_name == "INTEREST RATE")?.table_text;
                                        user.minimum_down_payment = w.data.find(d => d.table_name == "DOWN PAYMENT")?.table_text;
                                        return;
                                    }

                                    if (g.table_name2 == "YEARS" && g.table_text2 == f.year.value) {
                                        user.interes_rate = w.data.find(d => d.table_name == "INTEREST RATE")?.table_text2;
                                        user.minimum_down_payment = w.data.find(d => d.table_name == "DOWN PAYMENT")?.table_text2;
                                        return;
                                    }
                                })
                            }
                        }
                    }
                })
            }
        },
        loadTable: async function () {
            let model = {
                user: this.userSession.iduser,
                tabletype: `${this.userSession.role.toLowerCase()}_table`
            }
            const response = await UserTableService.getTables(model);

            let tableData = response.data.data[0].tabledata;
            let table = JSON.parse(tableData);
            table = table.find((c: any) => c.id === '4');
            // console.log(table);

            if (!table)
                return;

            let data = table.data.map((c: any) => c[0]);

            let propertyTaxes = data.find((c: any) => {
                return c.values.find((v: any) => v.value === 'PROPERTY TAXES')
            })

            let stateTaxes = data.find((c: any) => {
                return c.values.find((v: any) => v.value === 'STATE TAXES')
            })

            let propertyInsurance = data.find((c: any) => {
                return c.values.find((v: any) => v.value === 'PROPERTY INSURANCE')
            })
            let propertyFloodInsurance = data.find((c: any) => {
                return c.values.find((v: any) => v.value === 'PROPERTY FLOOD INSURANCE')
            })
            // console.log(data, propertyTaxes)

            if (propertyTaxes)
                this.financialInformation.propertyTaxes = this.getObject(propertyTaxes.values)

            if (stateTaxes)
                this.financialInformation.stateTaxes = this.getObject(stateTaxes.values)

            if (propertyInsurance)
                this.financialInformation.propertyInsurance = this.getObject(propertyInsurance.values)

            if (propertyFloodInsurance)
                this.financialInformation.propertyFloodInsurance = this.getObject(propertyFloodInsurance.values)

            this.calculateField("taxes");
        },
        getObject: function (values: any[]) {
            let object: any = {};
            values.forEach((el: any) => {
                object[el.name] = el.value;
            });

            return object;
        },
        getItTitleCompany: function (user: any) {
            this.selectedTitleCompany = user;
            this.financialInformation.propertyClosingCostBuy = this.selectedTitleCompany.tables.find((a: any) => a.tabletype == "titlecompany_table").tabledata.find((e: any) => e.id == "1").data.find((j: any) => j.table_name == "CLOSING CLOSE").table_closing_close;
        },
        submitScenario: async function ($event) {
            this.loaderConfig.isLoading = true;

            $event.disabled = true;

            let px = this.$route.name;

            if (this.foreign) {
                px = "foreign-" + this.foreign.replaceAll("_", "-");
            }

            let params: any = {
                code: px.toString().split("-").map(a => a[0].toUpperCase()).toString().replaceAll(",", "") + "-" + btoa(Date.now().toString()).toUpperCase().replaceAll("=", ""),
                date: moment(new Date()).format("YYYY-MM-DD"),
                type: this.scenarioType.scenario_type_id,
                user_email: this.customer.email,
                user_phonenumber: this.customer.phone,
                user_name: this.customer.name,
                data: {
                    personal_data: toRaw(this.selection.selectedUser),
                    financial_information: { ...this.financialInformation },
                    selection_data: { ...this.filters, ...this.selection },
                },
                id_user: this.userSession.iduser
            }

            if (this.foreign) {
                params.data.scenario_type = this.foreign;
            }

            params.data = JSON.stringify(params.data);

            let response = (await UserService.saveScenario(params)).data;

            if (!response.error) {
                Swal.fire({
                    title: "Realtor",
                    text: response.message,
                    icon: "success",
                    timer: 3500,
                    timerProgressBar: true,
                    showConfirmButton: false,
                    showCloseButton: false,
                    showCancelButton: false
                }).then(() => {
                    window.location.reload();
                })
            }
            else {
                console.log(response.message)
            }

            this.loaderConfig.isLoading = false;
            $event.disabled = false;
        }
    }
})
