
import UserService from "@/shared/services/user.service";
import { defineComponent, toRaw } from "vue";

export default defineComponent({
    data() {
        return {
            userRole: null,
            userSession: null,
        };
    },
    created() {
        this.init();
    },
    methods: {
        init: function () {
            this.getSession();
        },

        getSession: function () {
            let session =
                localStorage.getItem("usersession") &&
                localStorage.getItem("usersession").length > 0
                    ? JSON.parse(localStorage.getItem("usersession"))
                    : null;

            if (session) {
                this.userRole = session.role ? session.role : session.tipo_rol;
                this.userSession = session;
            } else {
                UserService.logout("Session expired!");
            }
        },
    },
});
