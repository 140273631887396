import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "content-dashboard" }
const _hoisted_2 = { class: "mlo-tables d-flex flex-column px-4 mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopBar = _resolveComponent("TopBar")!
  const _component_MLOTables = _resolveComponent("MLOTables")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TopBar, {
      helpBar: true,
      support: true,
      helpDescription: "Video tutorial for the use of the system",
      helpVideo: "https://www.realtortools.org/media/Video-MLO-tutorial.mp4"
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_MLOTables, {
        userID: _ctx.userSession.iduser,
        type: "investment"
      }, null, 8, ["userID"])
    ])
  ]))
}