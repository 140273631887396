
import UserService from "@/shared/services/user.service";
import UserTableService from "@/shared/services/usertable.service";
import { defineComponent, toRaw } from "vue";
import Loading from "vue-loading-overlay";
import { useToast } from "vue-toast-notification";
import ResidentialView from "./ResidentialView.vue";
import moment from "moment";
import useVuelidate from "@vuelidate/core";
import { required, email, numeric } from "@vuelidate/validators";
import Swal from "sweetalert2";
import Vue3Html2pdf from "vue3-html2pdf";
import { vMaska } from "maska";
//Number format
import VueNumberFormat from "vue-number-format";
import FormulaService from "@/shared/services/formula.service";
import { ref, computed } from 'vue';


export default defineComponent({
  data() {
    return {
      v$: useVuelidate(),
      userSession: null,
      userSection: null,
      scenarioType: null,
      configDatePicker: {
        date: null,
      },
      params: {
        scenario: {
          users: {
            name: "",
            phonenumber: "",
            from: "",
            to: "",
          },
          scenarios: {
            scenario_code: "",
          },
        },
      },
      scenario: {
        user: null,
        users: null,
        userScenarios: null,
        scenarioData: null,
      },
      filters: {
        property: null,
        score: null,
        program: null,
        year: null,
        program_name: "",
        active: false,
      },
      loaderConfig: {
        isLoading: true,
        fullPage: true,
      },
      listUserSections: [],
      listTableProperties: [],
      listScoreCredits: [
        {
          key: 0,
          name: "300 TO 579",
        },
        {
          key: 1,
          name: "580 TO 669",
        },
        {
          key: 2,
          name: "670 TO 739",
        },
        {
          key: 3,
          name: "740 TO 799",
        },
        {
          key: 4,
          name: "800 TO 850",
        },
      ],
      listPrograms: [
        {
          id: 0,
          name: "FHA",
        },
        {
          id: 1,
          name: "CONVENTIONAL",
        },
        {
          id: 2,
          name: "VA",
        },
      ],
      listYears: [
        {
          id: 0,
          name: "15 YEARS",
          value: 15,
        },
        {
          id: 1,
          name: "30 YEARS",
          value: 30,
        },
      ],
      selection: {
        selectedUser: null,
        selectedProperty: null,
        selectedScoreCredit: null,
        selectedProgram: null,
        selectedYears: null,
      },
      financialInformation: {
        monthlyBudget: null,
        propertyPrice: null,
        downPayment: null,
        mortgage: null,
        pmi: null,
        propertyTaxes: null,
        stateTaxes: null,
        propertyInsurance: null,
        association: 0,
        totalMonthly: null,
        propertyClosingCostBuy: null,
        monthlyPayment: null,
        propertyPriceQuantity: 30,
        sortOut: null
      },
      videoTutorial1: null,
      customer: {
        name: null,
        phone: null,
        email: null,
      },
      scenarioMail: {
        email: null,
        subject: null,
        message: null,
        file: null,
        fileConfig: null,
      },
      listPropertyTypes: [],
      listTitleCompanies: [],
      listInspectors: [],
      selectedTitleCompany: null,
      calculating: false,
    };
  },
  directives: { maska: vMaska },
  components: {
    Loading,
    ResidentialView,
    Vue3Html2pdf,
    VueNumberFormat, //Component NumberFormat
  },
  validations: {
    customer: {
      name: { required },
      phone: { required, numeric },
      email: { required, email },
    },
    scenarioMail: {
      email: { required },
      subject: { required },
      message: { required },
    },
  },
  created() {
    this.init();
  },
  // mounted(){
  //   const buttons = document.querySelectorAll('button');
  //   buttons.forEach((button) => {
  //     if (button.textContent.trim() === 'REPORTS') {
  //       button.style.display = 'none';
  //     }
  //   });

  // },
  computed: {
    pmi() {
      let j = 0;
      if (this.selection.selectedUser) {
        this.financialInformation.pmi =
          (parseFloat(this.selection.selectedUser.average_mip_pmi) *
            this.financialInformation.mortgage) /
          12;
        let l =
          this.financialInformation.propertyPrice *
          (this.selection.selectedUser.minimum_down_payment ||
            this.selection.selectedUser.minimum_down_payment != "-"
            ? this.selection.selectedUser.minimum_down_payment / 100
            : 0);
        this.financialInformation.pmi =
          this.financialInformation.downPayment < l
            ? this.financialInformation.pmi
            : 0;
        console.log(
          this.selection.selectedUser.minimum_down_payment,
          l,
          this.financialInformation.pmi
        );
      } else {
        this.financialInformation.pmi = 0;
      }

      j = Math.round(this.financialInformation.pmi);

      return j / 100;
    },
    scenarioValid: function () {
      let k = true;

      if (!this.selection.selectedUser || !this.selection.selectedYears) {
        k = false;
      }

      if (this.v$.customer.$invalid) k = false;

      return k;
    },
    selecterYears(): any {
      if (this.selection.selectedYears !== undefined && this.selection.selectedYears !== null) {
        return this.selection.selectedYears.value
      } else {
        return 1
      }
    },
    sortOutRatio: function () {
      let interest_rate = 0;
      let years = 1;
      if (this.selection.selectedYears !== undefined && this.selection.selectedYears !== null) {
        years = this.selection.selectedYears.value * 12
      }
      if (this.selection.selectedUser) {
        interest_rate = parseFloat(this.selection.selectedUser.interes_rate.replace('%', ''))
      }
      return Math.pow(1 + ((interest_rate / 12) / 100), years) * ((interest_rate / 12) / 100);
    },
    sortOutRatio2: function () {
      let interest_rate = 0;
      let result = 0
      let years = 1;
      if (this.selection.selectedYears !== undefined && this.selection.selectedYears !== null) {
        years = this.selection.selectedYears.value * 12
      }
      if (this.selection.selectedUser) {
        interest_rate = parseFloat(this.selection.selectedUser.interes_rate.replace('%', ''))
        result = Math.pow(1 + (interest_rate / 12) / 100, years) - 1
      }
      return result
    },
    sortOutFactor: function () {
      let result = 0
      if (this.sortOutRatio !== 0 && this.sortOutRatio2 !== 0) {
        result = this.sortOutRatio / this.sortOutRatio2
      }
      return result
    },
    propertyPrice: function () {
      let sortOut = 0
      let result = 0
      if (this.financialInformation.sortOut !== null) {
        sortOut = this.financialInformation.sortOut
      }

      if (sortOut !== 0 && this.sortOutFactor !== 0) {
        result = sortOut / this.sortOutFactor
      }
      this.financialInformation.propertyPrice = parseFloat(result.toFixed(2))
      return parseFloat(result.toFixed(2))
    },
    loanAmount() {
      let result = 0
      if (this.propertyPrice !== 0) {
        result = this.propertyPrice - this.financialInformation.downPayment
      }
      return parseFloat(result.toFixed(2))
    },
    propertyTaxes() {
      let result = 0
      if (this.propertyPrice !== 0) {
        result = (this.propertyPrice * parseFloat(this.financialInformation.propertyTaxes.text) / 12) / 100
      }
      return parseFloat(result.toFixed(2))
    },
    federalTaxes() {
      let result = 0
      if (this.propertyPrice !== 0) {
        result = (this.propertyPrice * parseFloat(this.financialInformation.stateTaxes.text) / 12) / 100
      }
      return parseFloat(result.toFixed(2))
    },
    propertyInsurance() {
      let result = 0
      if (this.propertyPrice !== 0) {
        result = (this.propertyPrice * parseFloat(this.financialInformation.propertyInsurance.text) / 12) / 100
      }
      return parseFloat(result.toFixed(2))
    },
    monthlyPayment() {
      let interest_rate = 0;
      let result = 0
      let years = 1;
      if (this.selection.selectedYears !== undefined && this.selection.selectedYears !== null) {
        years = this.selection.selectedYears.value * 12
      }
      if (this.selection.selectedUser) {
        interest_rate = parseFloat(this.selection.selectedUser.interes_rate.replace('%', ''))
        const interestRateRatio = (interest_rate / 100) / 12

        const calcTop = interestRateRatio * this.loanAmount
        const calcBottom = 1 - (1 / Math.pow(1 + interestRateRatio, years))

        const interest = this.loanAmount * interestRateRatio
        const capital = calcTop / calcBottom - interest
        result = capital + interest
      }
      return parseFloat(result.toFixed(2))
    },
    totalMonthly() {
      let result = 0
      if (this.financialInformation.association !== null) {
        result = this.monthlyPayment + this.propertyTaxes + this.federalTaxes + this.propertyInsurance + this.financialInformation.association
      }
      return parseFloat(result.toFixed(2))
    }
  },
  watch: {
    financialInformation(value, oldValue) {
      console.log(value, oldValue);
    }
  },
  methods: {
    init: function () {
      this.getSession();
      this.loadSectionUsers();
      this.getScenarioType(this.$route.name);

      let _context = this;
      setTimeout(() => {
        // Property Price Change
        let el: any = document.getElementById("propertyPriceBudget");
        el.addEventListener("change", () => {
          // console.log(_context.financialInformation.propertyPrice)
          _context.calculateField("taxes");
        });

        let downPayment: any = document.getElementById("downPaymentBudget");
        downPayment.addEventListener("change", () => {
          _context.calculatePropertyPrice();
        });
      }, 2000);
      // this.getScenarioType(this.$route.name.toString().replace("-", "_"));
    },

    submitFormula: async function (
      property: string,
      event: any = null,
      values: any = null
    ) {
      const FInformation = {
        monthlyBudget: this.financialInformation.monthlyBudget,
        downPayment: this.financialInformation.downPayment,
        propertyPrice: this.financialInformation.propertyPrice
          ? this.financialInformation.propertyPrice
          : 0,
        propertyPriceQuantity: this.financialInformation.propertyPriceQuantity,
        interes_rate: this.selection.selectedUser.interes_rate
          ? this.selection.selectedUser.interes_rate
          : 0,
        selectedYears: this.selection.selectedYears.name,
        mortgage: this.financialInformation.mortgage,
        monthlyPayment: this.financialInformation.monthlyPayment
          ? this.financialInformation.monthlyPayment
          : 0,
        selectedProgram: this.selection.selectedProgram.name,
        pmi: this.pmi,
        propertyTaxes: this.financialInformation.propertyTaxes?.value
          ? this.financialInformation.propertyTaxes?.value
          : 0,
        stateTaxes: this.financialInformation.stateTaxes?.value
          ? this.financialInformation.stateTaxes?.value
          : 0,
        propertyInsurance: this.financialInformation.propertyInsurance?.value
          ? this.financialInformation.propertyInsurance?.value
          : 0,
        association: this.financialInformation.association
          ? this.financialInformation.association
          : 0,
        totalMonthly: this.financialInformation.totalMonthly
          ? this.financialInformation.totalMonthly
          : 0,
      };

      console.log("Finatial Information: ", FInformation);

      const suma =
        FInformation.monthlyBudget +
        FInformation.downPayment +
        FInformation.propertyPrice +
        FInformation.propertyPriceQuantity +
        FInformation.mortgage +
        FInformation.monthlyPayment +
        FInformation.pmi +
        FInformation.propertyTaxes +
        FInformation.stateTaxes +
        FInformation.propertyInsurance +
        FInformation.association +
        FInformation.totalMonthly;

      this.financialInformation.totalMonthly = suma;

      return {
        propertyMonthlyPayment: suma,
      };
    },
    changeRealtor: function ($event) {
      console.log($event);
      this.loadTable();
    },
    sendScenarioMail: async function ($event) {
      $event.target.disabled = true;
      this.loaderConfig.isLoading = true;
      let formdata = new FormData();
      let files: any[] = [this.scenarioMail.file];
      formdata.append("name", this.scenarioMail.email);
      formdata.append("to", this.scenarioMail.email);
      formdata.append("subject", this.scenarioMail.subject);
      formdata.append("message", this.scenarioMail.message);
      files.forEach((a, w) => {
        formdata.append("files[]", a);
      });

      const response = (await UserService.sendUserMail(formdata)).data;

      if (!response.error) {
        for (let w in this.scenarioMail) {
          this.scenarioMail[w] = null;
        }

        Swal.fire({
          title: "Message",
          text: "Mail sended!",
          icon: "success",
          timer: 3500,
          timerProgressBar: true,
          showConfirmButton: false,
          showCloseButton: false,
          showCancelButton: false,
        });
      } else {
        console.log(response);
      }
      $event.target.disabled = false;
      this.loaderConfig.isLoading = false;
    },
    generateMail: function () {
      let ref: any = this.$refs.html2Pdf;
      ref.generatePdf();
    },
    commaFormat(value) {
      if (!value) return "";
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    beforeFileGenerated: function (event) {
      this.scenarioMail.fileConfig = event;
      // this.scenarioMail.email = this.scenario.scenarioData.user.user_email;
      document.querySelector(".content-wrapper").innerHTML =
        document.getElementById("printable-target").innerHTML;
      document.querySelector(".content-wrapper").classList.add("tw-p-10");
      this.scenarioMail.fileConfig
        .html2pdf()
        .from(this.scenarioMail.fileConfig.pdfContent)
        .toPdf()
        .output("blob")
        .then((data: any) => {
          let file = new File(
            [data],
            this.$route.name
              .toString()
              .split("-")
              .map((a) => a[0].toUpperCase())
              .toString()
              .replaceAll(",", "") +
            "_" +
            this.scenario.scenarioData.user.user_name.toUpperCase() +
            "_" +
            moment().format("MMDDYYYY") +
            ".pdf",
            { type: "application/pdf" }
          );
          this.scenarioMail.file = file;
          let w: any = window;
          w.modalclose("#exampleModalEscenario1");
          w.openModal("exampleModalMessage");

          console.log(this.scenarioMail);
        });
    },
    printScenario: function () {
      document.getElementById("printable-template").innerHTML =
        document.getElementById("printable-target").innerHTML;
      window.print();
    },
    calculateField: async function (property) {
      if (property == "taxes") {
        if (
          this.financialInformation.propertyPrice &&
          this.selection.selectedUser
        ) {
          this.financialInformation.propertyTaxes.value =
            (parseFloat(this.financialInformation.propertyTaxes.text) *
              this.financialInformation.propertyPrice) /
            12 /
            100;
          this.financialInformation.stateTaxes.value =
            (parseFloat(this.financialInformation.stateTaxes.text) *
              this.financialInformation.propertyPrice) /
            12 /
            100;
          this.financialInformation.propertyInsurance.value =
            (parseFloat(this.financialInformation.propertyInsurance.text) *
              this.financialInformation.propertyPrice) /
            12 /
            100;
        }
      }

      if (property == "estimatemortgage") {
        if (
          this.financialInformation.propertyPrice &&
          this.financialInformation.downPayment
        ) {
          this.financialInformation.mortgage =
            this.financialInformation.propertyPrice -
            this.financialInformation.downPayment;
        }
      }

      if (property == "estimatemortgage" || property == "monthlypayment") {
        let params = {
          propertyPrice: this.financialInformation.propertyPrice,
          propertyDownPayment: this.financialInformation.downPayment,
          propertyMortgage: this.financialInformation.mortgage,
        };

        let r = await this.submitFormula(
          "propertyMonthlyPayment",
          null,
          params
        );

        if (r && r.propertyMonthlyPayment) {
          this.financialInformation.monthlyPayment = r.propertyMonthlyPayment;
        }
      }
    },
    x() {
      console.log("asdasdasdas");
    },
    openModal: function (option: any) {
      let _window: any = window;
      let bootstrap = _window.bootstrap;

      this.videoTutorial1 = option; // Asigna el video directamente a videoTutorial1

      const modal = new bootstrap.Modal("#exampleModal3");
      modal.show();

      const modalopen = document.getElementById("exampleModal3");
      modalopen.addEventListener("hidden.bs.modal", (event) => {
        this.videoTutorial1 = null; // Limpia la variable del video al cerrar el modal
      });
    },
    addUserExtraTables: async function () {
      let params: any = {
        user: this.userSession.iduser,
        tabletype: [
          "mlo_primary",
          "mlo_second",
          "mlo_investment",
          "mlo_special",
          "titlecompany_table",
          "usertable_mlo",
          "usertable_titlecompany",
        ],
      };

      params.tabletype = params.tabletype.toString();

      const response = (await UserTableService.getTables(params)).data;

      if (!response.error) {
        let data = response.data;
        let datatable = data.map((a) => {
          return {
            ...a,
            tabledata: UserTableService.convertTable(JSON.parse(a.tabledata)),
            id_tabletype: btoa(a.tabletype),
          };
        });

        let users = [...data.filter((a) => a.tabletype.includes("usertable"))];

        users.forEach((u, z) => {
          if (u.tabletype.includes("mlo")) {
            u.fha = null;
            u.isManuallyAdded = true;
            u.interes_rate = null;
            u.minimum_down_paymentlower = null;
            u.minimum_down_paymenthigher = null;
            u.average_mip_pmi = null;
            u.backend_ratio = null;
            u.maximum_loan = null;
            u.minimum_down_payment = null;

            u.tables = datatable.filter(
              (ut) =>
                !ut.tabletype.includes("usertable") &&
                !ut.tabletype.includes("titlecompany")
            );

            u.tables.forEach((usertable) => {
              let tabletype = usertable.tabletype;

              let type = {
                id: btoa(tabletype),
                name:
                  tabletype.split("_")[1][0].toUpperCase() +
                  tabletype
                    .split("_")[1]
                    .substring(1, tabletype.split("_")[1].length),
              };

              if (!this.listTableProperties.find((a) => a.id == type.id)) {
                this.listTableProperties.push(type);
              }
            });
          }

          if (u.tabletype.includes("titlecompany")) {
            u.tables = datatable.filter(
              (ut) =>
                !ut.tabletype.includes("usertable") &&
                ut.tabletype.includes("titlecompany")
            );
          }

          let user = { ...u, ...JSON.parse(u.tabledata) };
          delete user.tabledata;

          if (u.tabletype.includes("mlo")) this.listUserSections.push(user);
          if (u.tabletype.includes("titlecompany"))
            this.listTitleCompanies.push(user);
        });

        this.listTableProperties = this.listTableProperties
          .filter(
            (a) => !["investment", "foreign"].includes(a.name.toLowerCase())
          )
          .map((a) => {
            return {
              ...a,
              name: ["primary", "second"].includes(a.name.toLowerCase())
                ? a.name + " Property"
                : a.name + " Program",
            };
          });
        console.log(this.listUserSections);
      } else {
        this.$toast.error("Error loading tables", { position: "top-right" });
        console.log(response.message);
      }

      this.loaderConfig.isLoading = false;
    },
    changeDate: function () {
      if (this.configDatePicker.date) {
        (this.params.scenario.users.from = moment(
          toRaw(this.configDatePicker.date)[0]
        ).format("YYYY-MM-DD")),
          (this.params.scenario.users.to = moment(
            toRaw(this.configDatePicker.date)[1]
          ).format("YYYY-MM-DD"));
      } else {
        this.params.scenario.users.from = "";
        this.params.scenario.users.to = "";
      }
    },
    formatWithCommas(prop) {
      // Obtener el valor actual del input
      let value = this.financialInformation[prop];

      // Eliminar comas anteriores, si las hay
      value = value.replace(/,/g, "");
      // Formatear el número con comas
      value = parseFloat(value).toLocaleString("en");
      // Actualizar el valor en el modelo
      // this.financialInformation[prop] = value;
      let el: any = document.getElementById(prop);
      el.value = value;
    },
    formatInspectionDate: (date: any) => {
      return moment(date).format("DD/MM/YYYY");
    },
    loadScenario: function (scenario) {
      if (scenario) {
        let scenariodata = scenario.data ? JSON.parse(scenario.data) : null;
        if (!scenariodata) return;

        delete scenario.data;
        this.scenario.scenarioData = { ...scenario, ...scenariodata };
        console.log("Escenario data", this.scenario.scenarioData);
      }
    },
    loadScenarios: async function (user = null) {
      this.loaderConfig.isLoading = true;

      if (user) this.scenario.user = user;

      const params = {
        ...this.params.scenario.scenarios,
        createdby: this.userSession.iduser,
        user: this.scenario.user.scenario_user_id,
        scenario: this.scenarioType.scenario_type_id,
      };
      const response = (await UserService.getScenarios(params)).data;

      if (!response.error) {
        this.scenario.userScenarios = response.data;
        if (this.scenario.userScenarios.results.length > 0) {
          this.scenario.userScenarios.results =
            this.scenario.userScenarios.results.map((a) => {
              return {
                ...a,
                date: moment(a.date).format("DD/MM/YYYY"),
                type_name: a.type_name.replaceAll("_", " "),
              };
            });
        } else {
          this.scenario.userScenarios = [];
        }
      } else {
        this.$toast.error(response.message, { position: "top-right" });
        console.log(response.message);
      }

      this.loaderConfig.isLoading = false;
    },
    getScenarioUsers: async function () {
      this.loaderConfig.isLoading = true;

      const params = {
        ...this.params.scenario.users,
        scenario: this.scenarioType.scenario_type_id,
      };
      const response = (await UserService.getScenarioUsers(params)).data;

      console.log(params);

      if (!response.error) {
        this.scenario.users = response.data;
        if (this.scenario.users.results.length > 0) {
          this.scenario.users.results = this.scenario.users.results.map((a) => {
            return {
              ...a,
              create_date: moment(a.create_date).format("DD/MM/YYYY"),
            };
          });
        }
      } else {
        this.$toast.error(response.message, { position: "top-right" });
        console.log(response.message);
      }

      this.loaderConfig.isLoading = false;
    },
    getScenarioType: async function (name) {
      this.loaderConfig.isLoading = true;

      const params = { name: name };
      const response = await UserService.getScenarioTypes(params);

      if (!response.data.error) {
        if (response.data.data.length > 0) {
          this.scenarioType = response.data.data[0];
          this.getScenarioUsers();
        }
      } else {
        this.$toast.error(response.data.message, { position: "top-right" });
        console.log(response.data.message);
      }

      this.loaderConfig.isLoading = false;
    },
    getSession: function () {
      let session =
        localStorage.getItem("usersession") &&
          localStorage.getItem("usersession").length > 0
          ? JSON.parse(localStorage.getItem("usersession"))
          : null;

      if (session) {
        this.userSession = session;
      } else {
        UserService.logout("Session expired!");
      }
    },
    loadSectionUsers: async function () {
      this.loaderConfig.isLoading = true;
      let params = {
        zipcode: this.userSession?.zipcode,
      };

      let response = (await UserService.getSectionUsers(params)).data;

      if (!response.error) {
        this.userSection = toRaw(response.data);
        if (this.userSection.mlo > 0) {
          this.listUserSections = this.userSection.mlo_users;
          this.listTitleCompanies = toRaw(this.userSection.titlecompany_users);
          this.listTitleCompanies =
            this.listTitleCompanies.length > 0
              ? this.listTitleCompanies.map((w) => {
                if (w.tables && w.tables.length > 0) {
                  w.tables = w.tables.map((a: any) => {
                    a.tabledata = UserTableService.convertTable(
                      JSON.parse(a.tabledata)
                    );
                    return a;
                  });
                }

                return toRaw(w);
              })
              : [];

          this.listInspectors = toRaw(this.userSection.inspector_users);
          this.listInspectors =
            this.listInspectors.length > 0
              ? this.listInspectors.map((w) => {
                if (w.tables && w.tables.length > 0) {
                  w.tables = w.tables.map((a: any) => {
                    a.tabledata = UserTableService.convertTable(
                      JSON.parse(a.tabledata)
                    );
                    return a;
                  });
                }

                return toRaw(w);
              })
              : [];

          if (this.listUserSections.length > 0) {
            this.listUserSections = this.listUserSections.map((u) => {
              u.isManuallyAdded = false;
              u.fha = null;
              u.interes_rate = null;
              u.minimum_down_paymentlower = null;
              u.minimum_down_paymenthigher = null;
              u.average_mip_pmi = null;
              u.backend_ratio = null;
              u.maximum_loan = null;
              u.minimum_down_payment = null;

              if (u.tables && u.tables.length > 0) {
                u.tables = u.tables.map((t: any) => {
                  let tables = JSON.parse(t.tabledata);
                  let formatTables: any[] = [];

                  if (tables.constructor === Array) {
                    tables.map((c: any) => {
                      if (c.convert) {
                        let listdata: any = [];

                        c.data.forEach((v: any) => {
                          let j: any = {};

                          v.map((e: any) => {
                            let o: any = {};
                            e.values.forEach((w: any) => {
                              o[`${e.name}_${w.name}`] = w.value;
                            });

                            return o;
                          }).forEach((p: any) => {
                            for (let f in p) {
                              j[f] = p[f];
                            }
                          });

                          v = [j];
                          listdata.push(j);
                        });

                        c.data = listdata;
                      }

                      formatTables.push(c);
                    });
                  }

                  let tabletype = {
                    id: btoa(t.tabletype),
                    name:
                      t.tabletype.split("_")[1][0].toUpperCase() +
                      t.tabletype
                        .split("_")[1]
                        .substring(1, t.tabletype.split("_")[1].length),
                  };

                  if (
                    !this.listTableProperties.find(
                      (a) => a.id == tabletype.id
                    ) &&
                    t.tabletype !== "mlo_investment" &&
                    t.tabletype !== "usertable_mlo" &&
                    t.tabletype !== "titlecompany_table" &&
                    t.tabletype !== "usertable_titlecompany" &&
                    t.tabletype !== "realtor_table"
                  ) {
                    this.listTableProperties.push(tabletype);
                  }

                  return {
                    ...t,
                    id_tabletype: tabletype.id,
                    tabledata: tables,
                  };
                });
              }

              return toRaw(u);
            });

             //console.log(this.listUserSections)
          }
        }

        this.addUserExtraTables();
      } else {
        console.log(response.message);
      }

      this.loaderConfig.isLoading = false;
    },
    roundNumber: function (number) {
      if (!number) return 0;
      return Math.round(number);
    },
    filterTable: function () {
      let f: any = {
        property: this.listTableProperties.find(
          (a) => a.id == this.filters.property
        ),
        score: this.filters.score,
        program: this.listPrograms.find((a) => a.id == this.filters.program),
        year: this.listYears.find((a) => a.id == this.filters.year),
      };

      let q = 0;

      for (let a in f) {
        if (f[a] != undefined) {
          q++;
        }
      }

      if (q == 4) {
        this.filters.active = true;
        this.filters.program_name = f.program.name;
        this.listUserSections.forEach((user: any) => {
          user.fha = null;
          user.interes_rate = "-";
          user.minimum_down_paymentlower = "-";
          user.minimum_down_paymenthigher = "-";
          user.average_mip_pmi = "-";
          user.backend_ratio = "-";
          user.maximum_loan = "-";
          user.minimum_down_payment = "-";

          if (user.tables) {
            let t: any = user.tables.find(
              (b: any) => b.id_tabletype == f.property.id
            );

            let g = t.tabledata.find((k) => k.id == 12);
            if (g) {
              g.data.forEach((e) => {
                if (e.table_name == f.program.name) {
                  user.minimum_down_payment = e.table_value;
                }
              });
            }

            if (t) {
              let y: any = t.tabledata.find(
                (o: any) => o.id == (f.year.id + 1).toString()
              );
              if (y) {
                const w =
                  f.program.name.toLowerCase() == "conventional"
                    ? "coventional"
                    : f.program.name.toLowerCase();

                y.data.forEach((e: any) => {
                  if (e[w + "_credit"]) {
                    let min = parseFloat(e[w + "_credit"].split("-")[0].trim());
                    let max = parseFloat(e[w + "_credit"].split("-")[1].trim());
                    let s = parseFloat(f.score);

                    if (s >= min && s <= max) {
                      user.interes_rate = e[w + "_percent"] + "%";

                      t.tabledata.forEach((j: any) => {
                        let k = j.data.find(
                          (a: any) =>
                            a?.table_name?.toLowerCase() == w ||
                            a?.table_name
                              ?.toLowerCase()
                              .includes(f.program.name.toLowerCase())
                        );
                        if (k) {
                          if (k?.table_minimmum_down_payment)
                            user.minimum_down_paymentlower =
                              k?.table_minimmum_down_payment + "%";
                          if (k?.table_score_credit_higher_than_b)
                            user.minimum_down_paymenthigher =
                              k?.table_score_credit_higher_than_b + "%";
                          // if (k?.table_mip || k?.table_pmi) user.average_mip_pmi = (k?.table_pmi ? k?.table_pmi : k?.table_mip) + "%";
                          if (k?.table_backend_ratio)
                            user.backend_ratio = k?.table_backend_ratio + "%";
                          if (k?.table_input_mla)
                            user.maximum_loan = "$" + k?.table_input_mla;

                          if (k?.table_mip || k?.table_pmi) {
                            // if (this.filters.program_name == "FHA" || this.filters.program_name == "VA") user.average_mip_pmi = k?.table_mip + "%";
                            // else user.average_mip_pmi = k?.table_pmi + "%";
                            user.average_mip_pmi = k?.table_pmi + "%";
                          }
                        }
                      });
                    }
                  }
                });
              }
            }
          }
        });
      }
    },
    sendData() {
      Swal.fire({
        title: "Message",
        text: "Information Sent",
        icon: "success",
        timer: 3500,
        timerProgressBar: true,
        showConfirmButton: false,
        showCloseButton: false,
        showCancelButton: false,
      });
    },
    loadData: function (user: any) {
      console.log(user);
      this.selection.selectedUser = user;
      this.selection.selectedProperty = this.listTableProperties.find(
        (c) => c.id == this.filters.property
      );
      this.selection.selectedProgram = this.listPrograms.find(
        (c) => c.id == this.filters.program
      );
      this.selection.selectedYears = this.listYears.find(
        (c) => c.id == this.filters.year
      );

      this.runSimulation();
      console.log(user, this.selection);

      Swal.fire({
        title: "Message",
        text:
          this.selection.selectedUser.username +
          " " +
          this.selection.selectedUser.lastname +
          " selected",
        icon: "success",
        timer: 3500,
        timerProgressBar: true,
        showConfirmButton: false,
        showCloseButton: false,
        showCancelButton: false,
      });
    },
    runSimulation: function () {
      //formula
      this.financialInformation.mortgage = 0;
      this.loadTable();
    },
    calculatePropertyPrice: function () {
      if (!this.selection.selectedUser) return;
      let pmi = this.selection.selectedUser.average_mip_pmi.replace("%", "");
      // this.financialInformation.propertyPrice = parseFloat(this.financialInformation.mortgage) + parseFloat(this.financialInformation.downPayment);
      this.financialInformation.pmi =
        (this.financialInformation.mortgage * pmi) / 100 / 12;
    },
    loadTable: async function () {
      let model = {
        user: this.userSession.iduser,
        tabletype: `${this.userSession.role.toLowerCase()}_table`,
      };
      const response = await UserTableService.getTables(model);

      let tableData = response.data.data[0].tabledata;
      let table = JSON.parse(tableData);
      table = table.find((c: any) => c.id === "4");

      if (!table) return;

      let data = table.data.map((c: any) => c[0]);

      let propertyTaxes = data.find((c: any) => {
        return c.values.find((v: any) => v.value === "PROPERTY TAXES");
      });

      let stateTaxes = data.find((c: any) => {
        return c.values.find((v: any) => v.value === "STATE TAXES");
      });

      let propertyInsurance = data.find((c: any) => {
        return c.values.find((v: any) => v.value === "PROPERTY INSURANCE");
      });
      // console.log(data, propertyTaxes)

      if (propertyTaxes)
        this.financialInformation.propertyTaxes = this.getObject(
          propertyTaxes.values
        );

      if (stateTaxes)
        this.financialInformation.stateTaxes = this.getObject(
          stateTaxes.values
        );

      if (propertyInsurance)
        this.financialInformation.propertyInsurance = this.getObject(
          propertyInsurance.values
        );

      this.calculateField("taxes");
    },
    getObject: function (values: any[]) {
      let object: any = {};
      values.forEach((el: any) => {
        object[el.name] = el.value;
      });

      return object;
    },
    // saveBudget: async function() {

    //     // console.log(model);
    //     const response = await UserTableService.saveBudget(model);

    //     if(response.data) {
    //         let $toast = useToast()
    //         $toast.success("Budget saved!", { position: 'top-right' });
    //         location.reload();
    //     } else {
    //         let $toast = useToast()
    //         $toast.success("There was an error, please try again.", { position: 'top-right' })
    //     }
    // },
    getPercentage: function (percentage: any) {
      let number: any = parseFloat(percentage.replace("%", ""));
      return number;
    },
    getCost: function (percentage: any) {
      let number: any = parseFloat(percentage.replace("$", ""));
      return number;
    },
    calculateMonthlyPayment: function () {
      this.financialInformation.totalMonthly = 900;
    },
    getItTitleCompany: function (user: any) {
      this.selectedTitleCompany = user;
      this.financialInformation.propertyClosingCostBuy =
        this.selectedTitleCompany.tables
          .find((a: any) => a.tabletype == "titlecompany_table")
          .tabledata.find((e: any) => e.id == "1")
          .data.find(
            (j: any) => j.table_name == "CLOSING CLOSE"
          ).table_closing_close;

      Swal.fire({
        title: "Title Company",
        text: this.selectedTitleCompany.companyname + " selected",
        icon: "success",
        timer: 3500,
        timerProgressBar: true,
        showConfirmButton: false,
        showCloseButton: false,
        showCancelButton: false,
      });
    },
    submitScenario: async function ($event) {
      this.loaderConfig.isLoading = true;

      $event.disabled = true;

      let data: any = {
        property: this.selection.selectedProperty.name,
        scoreCreditMlos: parseFloat(this.filters.score),
        programMlos: this.selection.selectedProgram.name,
        years: parseInt(this.selection.selectedYears.name.replace(" YEARS")),
        nameMlo: `${this.selection.selectedUser.username} ${this.selection.selectedUser.lastname}`,
        phoneMlo: this.selection.selectedUser.phonenumber,
        emailMlo: this.selection.selectedUser.email,
        interestRate: this.getPercentage(
          this.selection.selectedUser.interes_rate
        ),
        minimunDownPaymentLower: this.getPercentage(
          this.selection.selectedUser.minimum_down_paymentlower
        ),
        minimunDownPaymentHigher: this.getPercentage(
          this.selection.selectedUser.minimum_down_paymenthigher
        ),
        mipPmi: this.getPercentage(this.selection.selectedUser.average_mip_pmi),
        backEndRatio: this.getPercentage(
          this.selection.selectedUser.backend_ratio
        ),
        maximumLoanNotjumbo: this.getCost(
          this.selection.selectedUser.maximum_loan
        ),
        monthlyBudgetPayment: this.financialInformation.monthlyBudget,
        estimateMortgage: this.financialInformation.mortgage,
        downPayment: this.financialInformation.downPayment,
        propertyPrice: this.financialInformation.propertyPrice,
        propertyTaxes: this.financialInformation.propertyTaxes.text,
        stateTaxes: this.financialInformation.stateTaxes.text,
        propertyInsurance: this.financialInformation.propertyInsurance.text,
        association: this.financialInformation.association,
        totalMonthly: this.financialInformation.totalMonthly,
      };

      let params: any = {
        code:
          this.$route.name
            .toString()
            .split("-")
            .map((a) => a[0].toUpperCase())
            .toString()
            .replaceAll(",", "") +
          "-" +
          btoa(Date.now().toString()).toUpperCase().replaceAll("=", ""),
        date: moment(new Date()).format("YYYY-MM-DD"),
        type: this.scenarioType.scenario_type_id,
        user_email: this.customer.email,
        user_phonenumber: this.customer.phone,
        user_name: this.customer.name,
        data: {
          personal_data: toRaw(this.selection),
          financial_information: data,
          selection: this.selection,
        },
        id_user: this.userSession.iduser,
      };

      params.data = JSON.stringify(params.data);

      let response = (await UserService.saveScenario(params)).data;

      if (!response.error) {
        Swal.fire({
          title: "Realtor",
          text: response.message,
          icon: "success",
          timer: 3500,
          timerProgressBar: true,
          showConfirmButton: false,
          showCloseButton: false,
          showCancelButton: false,
        }).then(() => {
          window.location.reload();
        });
      } else {
        console.log(response.message);
      }

      this.loaderConfig.isLoading = false;
      $event.disabled = false;
    },
  },
});
