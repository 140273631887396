export const currencyFormat = (value) => {
	if (!value) return '$0';

	// Convert to string and remove any existing formatting
	const number = value.toString().replace(/[$,\s]/g, '');

	// Format the number
	try {
		const formattedNumber = Number(number).toLocaleString('en-US', {
			style: 'currency',
			currency: 'USD',
			minimumFractionDigits: 0,
			maximumFractionDigits: 0,
		});
		return formattedNumber;
	} catch {
		return '$0.00';
	}
};
